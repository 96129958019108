<template>
  <div class="tableWrap">
    <div
      class="table-top-bar"
      v-if="!selectedRowScopes.length"
    >
      <div class="leftBar">
        <el-button
          type="primary"
          size="small"
          :disabled="!accountId || !accountId.length"
          @click="f_create"
        >
          创建标准广告
        </el-button>
      </div>
      <div class="midFilter">
        <div v-show="!filterShow">
          <el-dropdown
            trigger="click"
            @command="handleFilterCommand"
            placement="bottom"
          >
            <el-button type="text">
              <el-image
                :src="filterIcon"
                style="width: 16px; height: 16px; margin-right: 5px; vertical-align: middle"
              ></el-image>
              <span style="color: #999"> 筛选 </span>
            </el-button>
            <el-dropdown-menu
              slot="dropdown"
              style="width: 460px"
              ref="filterDrop"
            >
              <div class="cusFilter">
                <div
                  class="cusFilterList"
                  v-if="initData"
                >
                  <div
                    class="cusFilterItem"
                    v-for="(filterObj, index) in cusFilterList"
                    :key="index"
                  >
                    <el-row
                      align="middle"
                      :gutter="5"
                    >
                      <el-col :span="6">
                        <el-select
                          size="mini"
                          v-model="filterObj.field"
                          popper-class="dropselect"
                          @change="fieldChange(cusFilterList, filterObj, index)"
                        >
                          <el-option
                            v-for="filterItem in initData.FilterFields"
                            :key="filterItem.keyName"
                            :label="filterItem.name"
                            :value="filterItem.keyName"
                            :disabled="cannotChangeField(cusFilterList, filterItem)"
                          >
                            <span @click="showFilterDrop">
                              {{ filterItem.name }}
                            </span>
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="5">
                        <el-select
                          size="mini"
                          v-model="filterObj.operator"
                          v-if="filterObj.field"
                          popper-class="dropselect"
                          :disabled="filteringList(filterObj).length == 1"
                        >
                          <el-option
                            v-for="operators in filteringList(filterObj)"
                            :key="operators.key"
                            :label="operators.desc"
                            :value="operators.key"
                          >
                            <span @click="showFilterDrop">{{ operators.desc }}</span>
                          </el-option>
                        </el-select>
                        <el-select
                          size="mini"
                          v-model="filterObj.operator"
                          disabled
                          popper-class="dropselect"
                          v-else
                        >
                        </el-select>
                      </el-col>
                      <el-col :span="11">
                        <el-select
                          size="mini"
                          v-if="filterObj.field == 'objective'"
                          v-model="filterObj.value1"
                          multiple
                          popper-class="dropselect"
                        >
                          <el-option
                            v-for="filterItem in initData.CampaignObjective"
                            :key="filterItem.key"
                            :label="filterItem.desc"
                            :value="filterItem.key"
                          >
                            <span @click="showFilterDrop">
                              {{ filterItem.desc }}
                            </span>
                          </el-option>
                        </el-select>
                        <el-select
                          size="mini"
                          v-if="filterObj.field == 'status'"
                          v-model="filterObj.value1"
                          multiple
                          popper-class="dropselect"
                        >
                          <el-option
                            v-for="filterItem in initData.FilteringStatus"
                            :key="filterItem.key"
                            :label="filterItem.desc"
                            :value="filterItem.key"
                          >
                            <span @click="showFilterDrop">
                              {{ filterItem.desc }}
                            </span>
                          </el-option>
                        </el-select>
                        <el-input
                          size="mini"
                          v-if="filterObj.field != 'status' && filterObj.field != 'objective'"
                          v-model="filterObj.value"
                        ></el-input>
                      </el-col>
                      <el-col :span="2">
                        <i
                          class="el-icon-close"
                          v-if="cusFilterList.length > 1"
                          @click="deleFilterObj(index)"
                          style="margin-top: 5px"
                        ></i>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="btnGroup">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="saveFilterSearch"
                  >
                    确定
                  </el-button>
                  <el-button
                    size="mini"
                    @click="addFilter"
                  >
                    添加
                  </el-button>
                </div>
              </div>
              <el-dropdown-item
                disabled
                divided
                style="font-size: 12px"
              >
                你的筛选组合
              </el-dropdown-item>
              <el-dropdown-item
                v-for="(filter, index) in filterList"
                :key="filter.id + index"
                :command="filter"
                :class="[filterId && filterId == filter.id ? 'active' : '']"
              >
                <div class="columnName">
                  <span>{{ filter.name }}</span>
                  <div>
                    <p>
                      <i
                        class="el-icon-delete"
                        v-if="filter.id"
                        @click.stop="deleFilterGroup(filter.id)"
                      ></i>
                    </p>
                  </div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          class="filterWrap"
          v-show="filterShow"
        >
          <el-image
            :src="filterIcon"
            style="width: 14px; height: 14px"
          ></el-image>
          <div
            class="paramsFilters"
            v-if="params.filtering && params.filtering.length"
          >
            <el-tag
              :key="i"
              v-for="(tags, i) in params.filtering"
              closable
              :disable-transitions="false"
              @close="deleFilterTag(tags, i)"
              size="mini"
              effect="dark"
              style="border-radius: 10px; font-size: 12px"
            >
              <div>
                <el-dropdown
                  trigger="click"
                  placement="bottom"
                >
                  <span
                    style="color: #fff"
                    v-if="initData.FilterFields"
                    >{{ formatFilterNameTable(tags) | showName }}</span
                  >
                  <el-dropdown-menu slot="dropdown">
                    <el-radio-group
                      v-model="tags.field"
                      @change="fieldChange(params.filtering, tags, i)"
                    >
                      <p
                        v-for="filterItem in initData.FilterFields"
                        :key="filterItem.keyName"
                        style="padding: 10px"
                      >
                        <el-radio
                          :label="filterItem.keyName"
                          :disabled="cannotChangeField(params.filtering, filterItem)"
                        >
                          {{ filterItem.name }}
                        </el-radio>
                      </p>
                    </el-radio-group>
                  </el-dropdown-menu>
                </el-dropdown>
                <span style="margin: 0 5px">
                  <span v-if="tags.field == 'status' || tags.field == 'objective'">
                    {{ operatorName(tags) || '包含' }}
                  </span>
                  <el-dropdown
                    trigger="click"
                    placement="bottom"
                    v-else
                  >
                    <span style="color: #fff">
                      {{ operatorName(tags) || '请选择' }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-radio-group
                        v-model="tags.operator"
                        @change="operatorChange"
                      >
                        <p
                          v-for="filterItem in showFiltering(tags)"
                          :key="filterItem.key"
                          style="padding: 10px"
                        >
                          <el-radio :label="filterItem.key">
                            {{ filterItem.desc }}
                          </el-radio>
                        </p>
                      </el-radio-group>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
                <span>
                  <el-dropdown
                    trigger="click"
                    placement="bottom"
                    v-if="tags.field == 'status' || tags.field == 'objective'"
                  >
                    <span style="color: #fff">
                      {{ valueName(tags) || '请选择' }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-checkbox-group
                        v-model="tags.value1"
                        @change="valueChange"
                      >
                        <p
                          v-for="(checkbox, index) in initData[
                            tags.field == 'status' ? 'FilteringStatus' : 'CampaignObjective'
                          ]"
                          :key="index"
                          style="padding: 10px"
                        >
                          <el-checkbox :label="checkbox.key">
                            {{ checkbox.desc }}
                          </el-checkbox>
                        </p>
                      </el-checkbox-group>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <span v-else>
                    <el-input
                      v-model="tags.value"
                      class="tagInput"
                      @change="valueChange"
                    ></el-input>
                  </span>
                </span>
              </div>
            </el-tag>
            <el-tag
              size="mini"
              style="border-radius: 10px; font-size: 12px; border: 1px dashed #409eff; cursor: pointer"
              @click="addTag"
            >
              <i class="el-icon-plus"></i>
              <span>添加筛选项</span>
            </el-tag>
          </div>
          <div class="rightIcon">
            <i
              class="el-icon-finished"
              @click="saveFilterShow = true"
            ></i>
            <i
              class="el-icon-circle-close"
              @click="closeMyFilter"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="rightTools"
        :style="{ flex: searchInput ? '260px 0 0' : '100px 0 0' }"
      >
        <div class="searchInput">
          <el-input
            clearable
            placeholder="请输入内容"
            v-model="params.keyword"
            class="input-with-select"
            v-if="searchInput"
            @change="keywordChange"
          ></el-input>
          <el-button
            type="text"
            icon="el-icon-search"
            @click="searchInput = !!params.keyword || !searchInput"
          ></el-button>
        </div>
        <div class="tools">
          <div class="toolsItem">
            <el-tooltip
              class="item"
              effect="dark"
              content="定制列"
              placement="top"
            >
              <el-dropdown
                trigger="click"
                @command="handleColumnCommand"
                placement="bottom"
              >
                <el-button
                  type="text"
                  class="el-icon-s-grid"
                ></el-button>
                <el-dropdown-menu
                  slot="dropdown"
                  style="width: 300px"
                >
                  <el-dropdown-item command="定制列组合"> 定制列组合 </el-dropdown-item>
                  <el-dropdown-item
                    disabled
                    divided
                    style="font-size: 12px"
                  >
                    你的列组合
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-for="column in myCols.myColumnsArr"
                    :key="column.id"
                    :command="column"
                    :class="[myCols.myColumsId == column.id ? 'active' : '']"
                  >
                    <div class="columnName">
                      <span>{{ column.name }}</span>
                      <div v-if="!column.idefault">
                        <p>
                          <i
                            class="el-icon-edit"
                            @click.stop="editColumns(column)"
                          ></i>
                          <i
                            class="el-icon-delete"
                            @click.stop="deleColumns(column)"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-tooltip>
          </div>
          <!-- <div class="downLoad">
            <down-load
              :downLoadParams="{ ...downLoadParams, ids: [] }"
            ></down-load>
          </div> -->
        </div>
      </div>
    </div>
    <div>
      <div
        class="toolsBar"
        v-if="selectedRowScopes.length"
      >
        <div class="toolsCon">
          <p>已选{{ selectedRowScopes.length }}项</p>
          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setStatus(false, 'ENABLED')"
            >
              启动
            </el-button>
          </div>

          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setStatus(false, 'PAUSED')"
            >
              暂停
            </el-button>
          </div>
          <!-- <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="handleEditCommand({ type: 'adsets' })"
              v-if="selectedRowScopes.find((v) => v.level == 'adset')"
            >
              编辑
            </el-button>
            <el-button
              type="primary"
              plain
              size="mini"
              @click="handleEditCommand({ type: 'editAds' })"
              v-if="selectedRowScopes.find((v) => v.level == 'ad')"
            >
              编辑
            </el-button>
          </div> -->
          <div class="operate-btn">
            <el-dropdown
              trigger="click"
              @command="handleEditCommand"
              placement="bottom"
            >
              <!-- <el-button type="primary" plain size="mini">批量操作</el-button> -->
              <el-dropdown-menu
                slot="dropdown"
                style="width: 260px"
              >
                <el-dropdown-item
                  disabled
                  style="font-size: 12px"
                >
                  <div class="columnName">
                    <span>广告系列</span>
                    <div>
                      已选
                      {{ selectedRowScopes.filter((v) => v.level == 'campaign').length }}
                      项
                    </div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'campaign' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'campaign').length"
                >
                  修改预算与竞价
                </el-dropdown-item>
                <el-dropdown-item
                  disabled
                  style="font-size: 12px"
                >
                  <div class="columnName">
                    <span> 广告组 </span>
                    <div>已选{{ selectedRowScopes.filter((v) => v.level == 'adset').length }}项</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'adset' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'adset').length"
                >
                  修改预算与竞价
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'adsetPix' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'adset').length"
                >
                  批量修改像素
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'adsetName' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'adset').length"
                >
                  批量修改广告组名称
                </el-dropdown-item>
                <el-dropdown-item
                  disabled
                  style="font-size: 12px"
                >
                  <div class="columnName">
                    <span>广告</span>
                    <div>
                      已选
                      {{ selectedRowScopes.filter((v) => v.level == 'ad').length }}
                      项
                    </div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'adName' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'ad').length"
                >
                  批量修改广告名称
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'adUrl' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'ad').length"
                >
                  批量添加广告网域
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'editPages' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'ad').length"
                >
                  批量编辑广告主页
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="f_copy"
            >
              快速复制
            </el-button>
          </div> -->
          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setStatus(false, 'REMOVED')"
            >
              删除
            </el-button>
          </div>
        </div>
        <div class="close">
          <i
            class="el-icon-close"
            @click="clearIds"
          ></i>
        </div>
      </div>
    </div>
    <!--  v-loading="loading" -->
    <el-table
      class="my-table"
      :data="tableData"
      row-key="id"
      :lazy="lazy"
      :load="loadChildren"
      border
      v-infinite-scroll="{ load: loadMore, distance: 0 }"
      :infinite-scroll-disabled="infiniteScrollDisabled"
      ref="table"
      :default-sort="{ prop: 'effective_status', order: 'ascending' }"
      show-summary
      :summary-method="getSummaries"
      stripe
      :cell-style="cellStyle"
      :header-cell-class-name="headerCellClassName"
      empty-text="暂无数据，选择账号进行查询~"
      width="100%"
      @selection-change="handleSelectionChange"
      @expand-change="expandChange"
      @sort-change="sortChange"
      @row-click="rowClick"
    >
      <el-table-column type="selection"></el-table-column>

      <el-table-column
        prop="name"
        label="名称"
        width="360"
        sortable
      >
        <template slot-scope="scope">
          <ad-cell-name
            :level="level"
            :value="scope"
            :disabled="disAbled"
            :account-id="accountId"
            @scope-row-changed="refreshTableRow(scope)"
            @setStatus="setStatus"
            @copy="copyRow"
            @handle-edit-command="handleEditCommand"
            @open-next-level="openNextLevel"
            @view-audience="viewAudience"
            @edit-audience="editAudience"
          ></ad-cell-name>
        </template>
      </el-table-column>
      <el-table-column
        prop="effective_status"
        label="状态"
        width="100"
        sortable="custom"
      >
        <template #header>
          <div class="statusHeader">
            <span>状态</span>
            <div
              @click.stop
              style="display: inline-block"
            >
              <el-popover
                v-if="initData.FilteringStatus"
                placement="bottom"
                width="80"
                trigger="click"
              >
                <el-checkbox-group
                  v-model="checkList"
                  class="toolsCheckTips"
                  @change="selectStatus"
                >
                  <el-checkbox
                    :label="checkbox.key"
                    v-for="checkbox in initData.FilteringStatus"
                    :key="checkbox.key"
                  >
                    {{ checkbox.desc }}
                  </el-checkbox>
                </el-checkbox-group>
                <i
                  slot="reference"
                  class="el-icon-discount"
                ></i>
              </el-popover>
            </div>
          </div>
        </template>
        <template
          slot-scope="scope"
          v-if="scope.row.effective_status"
        >
          <div
            class="statusWarp"
            v-if="initData.AdStatus"
          >
            <span
              v-if="scope.row.effective_status != 'DELETED' && scope.row.effective_status != 'ARCHIVED'"
              :class="['pointer', scope.row.effective_status]"
            ></span>
            <span :class="['name', `name${scope.row.effective_status}`]">
              {{ initData.AdStatus.filter((v) => v.key == scope.row.effective_status)[0].desc }}
            </span>
            <el-tooltip
              v-if="scope.row.status_hints && scope.row.status_hints.length"
              placement="bottom"
              width="600"
              trigger="hover"
              :enterable="true"
              effect="light"
              popper-class="rule-tip"
            >
              <div slot="content">
                <div
                  class="rule-score-wrap"
                  v-for="(item, index) in scope.row.status_hints"
                  :key="index"
                >
                  <div>
                    <i
                      class="el-icon-warning"
                      v-if="item.level == 'WARN'"
                    ></i>
                    <i
                      class="el-icon-error"
                      v-if="item.level == 'ERROR'"
                    ></i>
                    <span>{{ item.message }}</span>
                  </div>
                  <div
                    v-if="item.alertId"
                    style="margin-left: 5px"
                  >
                    <el-button
                      v-if="item.alertScore == null || item.alertScore == 1"
                      type="text"
                      size="mini"
                      :disabled="item.alertScore == 1"
                      @click="
                        reviewScore({
                          curTableRow: scope.row,
                          curTableIndex: scope.$index,
                          curRow: item,
                          curIndex: index,
                          flag: 1,
                        })
                      "
                    >
                      准确
                    </el-button>

                    <el-button
                      v-if="item.alertScore == null || item.alertScore === 0"
                      type="text"
                      size="mini"
                      :disabled="item.alertScore === 0"
                      @click="
                        reviewScore({
                          curTableRow: scope.row,
                          curTableIndex: scope.$index,
                          curRow: item,
                          curIndex: index,
                          flag: 0,
                        })
                      "
                    >
                      不准确
                    </el-button>
                  </div>
                </div>
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="effective_status"
        label="是否关联商品"
        width="120"
        v-if="level == 'campaign'"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.level == 'campaign'">
            <span v-if="scope.row.campaign_ref == 'NOT_REF'">
              <el-button
                size="mini"
                @click.stop="relateSaleClick(scope)"
              >
                关联商品
              </el-button>
            </span>
            <span v-if="scope.row.campaign_ref == 'REFING'">
                <i class="el-icon-loading"></i>关联中...
              </span>
            <span v-if="scope.row.campaign_ref == 'REF'">
              <span>已关联</span>
            </span>
            <span v-else>
              <span></span>
            </span>
          </p>
          <p v-else>--</p>
        </template>
      </el-table-column>
      <!-- 其它非固定字段 -->
      <el-table-column
        :prop="item.keyName"
        :label="item.name"
        v-for="(item, index) in columns"
        :key="index"
        :min-width="columnWidth(item)"
        :sortable="item.hasSort || manualSort(item) ? 'custom' : item.hasSort"
        :align="item.keyName == 'budget' || item.keyName == 'product_report' ? '' : 'right'"
        header-align="left"
      >
        <template #header>
          <span>
            <el-popover
              placement="bottom"
              :title="item.name"
              width="460"
              trigger="hover"
              :content="item.remark"
              :disabled="!item.remark"
            >
              <span
                class="reference"
                slot="reference"
                >{{ item.name }}</span
              >
            </el-popover>
          </span>
        </template>
        <template slot-scope="scope">
          <!-- 广告系列预算 -->
          <ad-cell-inline-edit-budget
            :value="scope"
            :accountId="accountId"
            @scope-row-changed="refreshTableRow(scope)"
            :key="scope.row.level + scope.row.id + scope.row.name"
            v-if="item.keyName === 'budget'"
            :date-range="dateRange"
            @get-list="debouncedReloadTable"
          ></ad-cell-inline-edit-budget>
          <!-- 目标出价 -->
          <Ad-cell-inline-edit-target-cp
            :value="scope"
            :accountId="accountId"
            @scope-row-changed="refreshTableRow(scope)"
            :key="scope.row.level + scope.row.id + scope.row.name"
            v-else-if="item.keyName === 'target_cpa'"
            @get-list="debouncedReloadTable"
          ></Ad-cell-inline-edit-target-cp>
          <!-- tab切换-商品经营分析/广告系列经营分析（默认广告系列经营分析）-->
          <ad-cell-product-report-v4
            v-else-if="item.keyName === 'product_report'"
            :value="scope"
            :key="scope.row.level + scope.row.id + scope.row.name"
            :level="level"
            :assessLoading="assessLoading"
          ></ad-cell-product-report-v4>
          <!-- 广告类型 -->
          <div v-else-if="item.keyName === 'type'">
            <span class="alignLeft">{{
              scope.row.type === 'DISCOVERY' ||
              scope.row.type === 'UNSPECIFIED' ||
              scope.row.type === 'DISCOVERY_MULTI_ASSET_AD'
                ? '发现广告'
                : '视频广告'
            }}</span>
          </div>
          <div v-else-if="item.keyName === 'local_order'">
            <local-order
              :row="scope.row"
              :id="scope.row.id"
              :level='level'
            ></local-order>
          </div>
          <div v-else-if="item.keyName === 'local_event'">
            <div v-if="scope.row.local_event">
              <p
                class="color9"
                style="height: 20px; line-height: 20px"
                v-for="(item, index) in scope.row.local_event"
                :key="index"
              >
                {{ item.eventTypeDesc }}(成本)：{{ item.eventCount }}({{ item.costPrice }})
              </p>
            </div>
            <div v-else>--</div>
          </div>
          <div
            class="text-a-l"
            v-else-if="item.keyName === 'campaign_ref'"
          >
            <p v-if="scope.row.level == 'campaign'">
              <span v-if="scope.row.campaign_ref == 'NOT_REF'">
                <el-button
                  size="mini"
                  @click="relateSaleClick(scope)"
                  >关联商品</el-button
                >
              </span>
              <span v-if="scope.row.campaign_ref == 'REFING'"><i class="el-icon-loading"></i>关联中... </span>
              <span v-if="scope.row.campaign_ref == 'REF'"><span>已关联</span></span>
              <span v-else>
                <span></span>
              </span>
            </p>
            <p v-else>--</p>
          </div>
          <!-- 单独接口 点击加载 -->
          <span
            v-else
            v-html="formatedCellValueV2(scope.row, item)"
          ></span>
        </template>
      </el-table-column>
      <template
        v-if="tableData && tableData.length"
        slot="append"
      >
        <div class="loadMore">
          {{ loadText }}
        </div>
      </template>
    </el-table>
    <!-- 保存为我的筛选条件 -->
    <el-dialog
      title="保存查询条件"
      :visible.sync="saveFilterShow"
      width="500px"
    >
      <div>
        <el-form label-width="120px">
          <el-form-item label="查询条件名称">
            <el-input
              placeholder="请输入"
              v-model="filterName"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              type="primary"
              @click="saveMyFilter"
            >
              保存
            </el-button>
            <el-button
              @click="
                saveFilterShow = false;
                filterName = '';
              "
            >
              取消
            </el-button>
          </span>
        </div>
      </div>
    </el-dialog>
    <!-- 定制列弹窗 -->
    <cus-column-dialog
      :account-id="accountId"
      :visible="cusColumnVisible"
      :initCheckedColumns="initCheckedColumns"
      @close-dialog="cusColumnVisible = false"
      :name="myColumsName"
      @post-save-res="handleGetSaveRes"
      @post-cus-columns="handleGetCusColumns"
    ></cus-column-dialog>
    <!-- 编辑竞价预算 -->
    <!-- 关联商品 -->
    <!-- 关联商品 -->
    <related-goods
      :accountId="accountId"
      :relateSale="relateSale"
      @close="close('relateSale')"
      :rowMsg="rowMsg"
      @getList="refreshTableRow"
      platform="google"
    ></related-goods>
    <!-- 修改广告名称 -->
    <!-- 修改广告名称 -->

    <!-- 批量修改网域 -->
    <!-- 编辑主页 -->
    <!-- 查看受众报告 -->
    <audience-table
      :show.sync="showAudience"
      :adset="currentAudienceAdset"
      :account-ids="accountId"
      :fields="fields"
      @editAdgroup="f_editAudience"
    ></audience-table>
    <!-- 创建广告 -->
    <my-dialog :myDialogObj="createVisible">
      <create-gg-ad
        @closeCreate="f_closeCreate"
        v-if="createVisible.title.indexOf('创建') > -1"
        @changeAdTitles="f_changeAdTitles"
        :accountId="accountIdCreate"
        @success="reloadTable"
      ></create-gg-ad>
      <campain-form
        :currentNodeCampagin="currentNodeCampagin"
        v-if="createVisible.title.indexOf('复制广告系列') > -1"
        :accountId="accountId"
        :level="level"
        :list="list"
        @closeCreate="f_closeCreate"
      ></campain-form>
      <edit-adgroup
        v-if="createVisible.title.indexOf('编辑受众') > -1"
        :accountId="accountId"
        :originData="originData"
        :value="ruleForm"
        @closeCreate="f_closeCreate"
      ></edit-adgroup>
      <edit-ad
        @closeCreate="f_closeCreate"
        v-if="createVisible.title.indexOf('编辑广告') > -1"
        :accountId="accountId"
        :originData="originData"
        :value="ruleForm"
      ></edit-ad>
    </my-dialog>
  </div>
</template>

<script>
// 创建广告
import createGgAd from '@/views/google/createGgAd/index.vue';
import MyDialog from '@/views/google/createGgAd/common/myDialog/index.vue';
// 复制广告系列
import campainForm from '@/views/google/adManagement/copy/campainForm.vue';
import { mapState, mapGetters, mapMutations } from 'vuex';
// 编辑受众
import editAdgroup from '@/views/google/adManagement/editAdgroup/index.vue';
// 编辑广告（单个）
import editAd from '@/views/google/adManagement/editAd/index.vue';
import AdCellInlineEditBudget from '@/components/google/AdCellInlineEditBudget';
import AdCellInlineEditTargetCp from '@/components/google/AdCellInlineEditTargetCp';
import AdCellName from '@/components/google/AdCellName';
import cusColumnDialog from '@/components/google/cusColumnDialog/index';
import AdCellProductReportV4 from '@/views/adManagement/components/table/AdCellProductReportV4';
import localOrder from '@/views/adManagement/components/table/localOrder';
import relatedGoods from '@/views/adManagement/components/table/relatedGoods';
// 查看受众
import audienceTable from '@/components/google/audienceTable.vue';
import { adgroupAudienceInfo, adsInfo } from '@/api/google/ceateAdGg.js';
import { alertScore, bindAuto } from '@/api/adManagement';
import { myFilters, getAdsReport } from '@/api/google/adManagement';
import { deleteColumn, deleteFilter, saveFilter, mutateStatus, myColumns } from '@/api/google/adManagement';

const _ = require('lodash');
//筛选【封装】，细分，头部操作，自定义列，弹框，放表格里
///日期选择，放index
const statusList = [
  { key: 'ACTIVE', campaign_val: 1, adset_val: 1, ad_val: 1 },
  { key: 'PAUSED', campaign_val: 7, adset_val: 9, ad_val: 8 },
  { key: 'DELETED', campaign_val: 6, adset_val: 8, ad_val: 7 },
  { key: 'PENDING_REVIEW', campaign_val: 3, adset_val: 3, ad_val: 3 },
  { key: 'DISAPPROVED', campaign_val: 2, adset_val: 2, ad_val: 2 },
  { key: 'PREAPPROVED', campaign_val: 8, adset_val: 10, ad_val: 9 },
  { key: 'PENDING_BILLING_INFO', campaign_val: 9, adset_val: 11, ad_val: 10 },
  { key: 'CAMPAIGN_PAUSED', campaign_val: 10, adset_val: 7, ad_val: 11 },
  { key: 'ARCHIVED', campaign_val: 11, adset_val: 12, ad_val: 12 },
  { key: 'ADSET_PAUSED', campaign_val: 12, adset_val: 13, ad_val: 16 },
  { key: 'IN_PROCESS', campaign_val: 13, adset_val: 4, ad_val: 4 },
  { key: 'WITH_ISSUES', campaign_val: 14, adset_val: 14, ad_val: 5 },
  { key: 'NOAD', campaign_val: 4, adset_val: 6, ad_val: 13 },
  { key: 'CLOSED', campaign_val: 5, adset_val: 4, ad_val: 14 },
];
function getIndex(key, level) {
  return statusList.filter((v) => v.key == key).length
    ? statusList.filter((v) => v.key == key)[0][`${level}_val`]
    : 100;
}

function caseCade(str) {
  return str.charAt(0).toUpperCase() + str.substr(1);
}
export default {
  name: 'AdTable',
  props: {
    dateRange: {
      type: Object,
      default: () => ({}),
    },
    currentNodeCampagin: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: 'campaign',
    },
    activeLevel: {
      type: String,
      default: 'campaign',
    },
    accountId: {
      type: [String, Array],
      default: '',
    },
    initData: {
      type: Object,
      default: () => ({}),
    },
    adsCountObj: {
      type: Object,
      default: () => ({}),
    },
    selectIds: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      accountIdCreate: [],
      originData: {},
      ruleForm: {}, //修改受众
      newMap: {},
      createVisible: {
        title: '选择广告类型',
        dialogVisible: false,
        width: '1100px',
        top: '5vh',
      },
      assessLoading: false,
      adType: '创建标准广告',
      filterIcon: require('@/assets/icon/filter.png'),
      tableData: [],
      disAbled: false,
      // loading: false,
      fields: [],
      params: {
        account_id: this.accountId,
        action_attribution_windows: this.dateRange.actionAttributionWindows || [],
        after: '',
        date_end: this.dateRange.dateRange[1] || '2020-10-01',
        date_start: this.dateRange.dateRange[0] || '2020-02-20',
        fields: [],
        filtering: [],
        object_filtering: [],
        keyword: '',
        level: 'campaign',
        page_length: 50,
        sort: [],
      },
      after: '',
      expandAll: false, //是否展开所有下级，当加载细分的时候默认展开
      expandKeys: [],
      scrollDisable: false, //表格无限滚动是否禁用
      loadText: '滚动加载更多', //默认加载文字
      breakdowms: {
        time: '',
        delivery: '',
      },
      maps: new Map(),
      infiniteScrollDisabled: false,
      lazy: true,
      // 搜索框
      searchInput: false,
      // 自定义列
      initCheckedColumns: [],
      cusColumnVisible: false,
      myColumnsArr: [],
      myColumsName: '',
      myColumsId: '',
      myCols: {
        myColumnsArr: [],
        myColumsName: '',
        myColumsId: '',
      },
      // 筛选条件
      filterList: [], //我的自定义筛选条件
      cusFilterList: [{ field: '', operator: '', value: '' }],
      filterId: 0,
      filterShow: false,
      checkList: [],
      filterName: '',
      saveFilterShow: false,
      drawers: {
        copy: { visible: false, rowScopes: [] }, // 复制抽屉
        editBudgetPrice: { visible: false, rowScopes: [], level: this.level }, // 修改竞价预算
      },
      selectedRowScopes: [],
      // 修改广告广告组
      initDataAdCreat: {},
      editGroupShow: false,
      editAdsShow: false,
      editAdSetId: 0,
      editAdId: 0,
      // 取消上一次请求
      source: null,
      downLoadParams: null,
      relateSale: false, //关联商品
      rowMsg: null,
      // 批量编辑广告创意
      batchEditAds: false,
      adScope: null,
      adsetScope: null,
      creatAdsShow: false,
      creatAdParentId: null,
      editNameShow: false,
      editAdsetNameShow: false,
      editPixelShow: false,
      batchEditShow: false,
      editUrlShow: false,
      summary: {}, //合计行
      // 经营分析
      manageAssess: [],
      list: [],
      manualSortFlag: null,
      editPageShow: false,
      currentAudienceAdset: null,
      showAudience: false,
    };
  },
  components: {
    AdCellProductReportV4,
    AdCellName,
    AdCellInlineEditBudget,
    cusColumnDialog,
    localOrder,
    audienceTable,
    createGgAd,
    MyDialog,
    campainForm,
    editAdgroup,
    editAd,
    AdCellInlineEditTargetCp,
    relatedGoods,
  },
  filters: {
    showName(item) {
      return item ? (item['name'] ? item['name'] : '') : '请选择';
    },
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('num', ['selectedNum']),
    ...mapGetters('num', ['toFormatedMoneyV2', 'currentUiUnit']),
    ...mapGetters('adActiveTab', [
      'selectCampaignsOld',
      'selectCampaignsNew',
      'selectAdsetsOld',
      'selectAdsetsNew',
      'campaignDateRangOld',
      'campaignDateRangNew',
      'adsetDateRangNew',
      'adsetDateRangOld',
      'adDateRangNew',
      'adDateRangOld',
    ]),
    editCopyList() {
      return this.list.filter((v) => v.level == this.level);
    },
    columns() {
      const arr = ['status', 'id', 'name','campaign_ref'];
      console.log(
        this.fields.filter((v) => !arr.includes(v.keyName)),
        '99999999999999',
      );

      return this.fields.filter((v) => !arr.includes(v.keyName));
    },
    filteringList() {
      return (item) => {
        const filterObj = this.initData.FilterFields.find((v) => v.keyName == item.field);
        return filterObj ? filterObj.filtering : [];
      };
    },
  },
  watch: {
    accountId: async function (v, ov) {
      await this.myColumns();
      this.debouncedReloadTable();
    },
    dateRange: {
      handler(val) {
        this.setDateRangeParam();
      },
      deep: true,
    },
  },
  // 注册自定指令
  directives: {
    'infinite-scroll': {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper');
        selectWrap.addEventListener('scroll', function () {
          if (selectWrap.scrollHeight == selectWrap.clientHeight) return;
          let sign = binding.value.distance;
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value.load();
          }
        });
      },
    },
  },
  created() {
    let filteringStr = localStorage.getItem(`filter_${this.accountId}`);
    if (filteringStr && JSON.parse(filteringStr).length) {
      this.params.filtering = JSON.parse(filteringStr);
      this.filterShow = true;
    }
    this.debouncedReloadTable = _.debounce(this.reloadTable, 500);
  },
  mounted() {
    this.downLoadParams = JSON.parse(JSON.stringify(this.params));
    this.getMyFilter();
    this.debouncedReloadTable();
    this.$bus.$on('createAd', (data) => {
      console.log(data, 'data--------------------');
      this.accountIdCreate = [data.id];
      this.handleCommandClick();
    });
  },
  beforeDestroy() {
    this.$bus.$off('createAd');
  },
  updated() {
    this.$refs['table'].doLayout();
  },
  methods: {
    ...mapMutations(['audiences/setMultipleAdsets']),
    // 创建标准广告
    f_create() {
      this.accountIdCreate = this.accountId;
      this.handleCommandClick();
    },
    async f_editAdCommand(v) {
      this.$showLoading();
      let res = await adsInfo({ accountId: this.accountId[0], adResourceName: v.scope.row.resource_name });
      this.$hideLoading();
      if (res.code == 0) {
        this.ruleForm = res.data;
        this.originData = [res.data];
        this.createVisible = {
          title: '编辑广告',
          dialogVisible: true,
          width: '800px',
          top: '5vh',
        };
      }
    },
    // 根据广告类型改变弹窗标题
    f_changeAdTitles(t) {
      this.createVisible.title = `创建${t}广告`;
    },
    // 快速复制
    f_copy() {
      this.createVisible = {
        top: '8vh',
        width: '600px',
        title: '复制广告系列',
        dialogVisible: true,
        // modal:false,
        // append:false,
        // body:false,
      };
    },
    // 关闭弹窗
    f_closeCreate() {
      this.createVisible.dialogVisible = false;
    },
    alertScore({ id, score }) {
      let params = {
        id,
        score,
      };
      alertScore(params).then((res) => {
        if (res.code == 0) {
        }
      });
    },
    reviewScore({ curTableRow, curRow, curIndex, flag }) {
      if (curTableRow.level == this.level) {
        this.$refs.table.store.states.data.map((k, i) => {
          if (k.id == curTableRow.id) {
            this.$refs.table.store.states.data[i].status_hints[curIndex].alertScore = flag;
          }
        });
      } else {
        // 不是当前级别
        let parentId = curTableRow.level == 'adset' ? curTableRow.campaign_id : curTableRow.adset_id;
        let list = this.$refs.table.store.states.lazyTreeNodeMap[parentId];
        list.map((v, index) => {
          if (v.id == curTableRow.id) {
            this.$refs.table.store.states.lazyTreeNodeMap[parentId][index].status_hints[curIndex].alertScore = flag;
          }
        });
      }
      this.alertScore({ id: curRow.alertId, score: flag });
    },
    rowClick(row, column) {
      if (column && column.label && column.label != '预算') {
        this.$refs.table.toggleRowSelection(row);
      }
    },
    setManageAssess() {
      let sel = [],
        idKey = '';
      if (this.level == 'adset') {
        sel = this.selectIds.selectCampaigns;
        idKey = 'campaign_id';
      }
      let arr = [...this.tableData];
      arr.forEach((item, index) => {
        let obj = sel.find((s) => s.id == item[idKey]);
        if (obj) {
          item.product_report = obj.product_report;
          item.adProductReport = obj.adProductReport;
          item.campaignPurchase = obj.object_actions || obj.purchase || obj.purchase_web;
          item.campaignCost = obj.spend;
        }
      });
      this.tableData = [...arr];
    },
    cellStyle({ columnIndex }) {
      if (columnIndex == 1) {
        return 'height:60px!important;';
      }
    },
    headerCellClassName({ columnIndex }) {
      if (columnIndex == 2) {
        return 'header-status';
      }
    },
    // 关联商品
    relateSaleClick(row) {
      this.rowMsg = row;
      this.relateSale = true;
    },
    cancelQuest() {
      if (typeof this.source === 'function') {
        this.source(); //取消请求
      }
    },
    specialFields(params) {
      if (params.level === 'ad') {
        return ['creative'];
      }
      if (params.fields.indexOf('product_report') >= 0) {
        if (params.fields.indexOf('local_order') >= 0) {
          if (params.fields.indexOf('spend') > 0) {
            return ['adProductReport'];
          } else {
            return ['adProductReport', 'spend'];
          }
        } else {
          return ['adProductReport', 'local_order'];
        }
      }
      return [];
    },
    close(v) {
      this[v] = false;
    },
    setDateRangeParam() {
      const { actionAttributionWindows, dateRange } = this.dateRange;
      this.params.action_attribution_windows = actionAttributionWindows;
      [this.params.date_start, this.params.date_end] = dateRange;
      const level = caseCade(this.level);
      this.$store.commit(`adActiveTab/set${level}DateRangNew`, this.dateRange);
      this.debouncedReloadTable();
      this.$store.commit(`adActiveTab/set${level}DateRangOld`, this.dateRange);
    },
    formatFilterNameTable(tags) {
      if (!this.initData.FilterFields || (this.initData.FilterFields && this.initData.FilterFields.length == 0))
        return [];
      let arr = this.initData.FilterFields.filter((v) => v.keyName == tags.field) || [];
      return arr && arr.length ? arr[0] : arr;
    },
    formatedCellValue(row, columnDefine) {
      let keyName = columnDefine.keyName;
      let type = columnDefine.type;
      let v = row[keyName];
      let empty = !v || v === '0';
      if (empty) return '-';
      switch (type) {
        case 'MONEY':
          return this.actionValueDesc(keyName, this.formatedMoney(v, keyName !== 'bid_amount'), row);
        case 'PERCENTAGE':
          return parseFloat(v).toFixed(2) + '%';
        case 'INT':
          return this.actionValueDesc(keyName, parseInt(v), row);
        case 'DECIMAL':
          return parseFloat(v).toFixed(2);
        default:
          return v;
      }
    },
    formatedCellValueV2(row, columnDefine) {
      let { keyName, type } = columnDefine,
        v = row[keyName],
        empty = !v || v === '0',
        val = '--',
        desc = '',
        obj = null;
      if (!empty) {
        switch (type) {
          case 'MONEY':
            obj = this.actionValueDescV2(keyName, this.formatedMoney(v, keyName !== 'bid_amount'), row);
            break;
          case 'PERCENTAGE':
            val = parseFloat(v).toFixed(2) + '%';
            break;
          case 'INT':
            obj = this.actionValueDescV2(keyName, parseInt(v), row);
            break;
          case 'DECIMAL':
            val = parseFloat(v).toFixed(2);
            break;
          default:
            val = v;
            break;
        }
      }
      if (obj) {
        val = obj.val;
        desc = obj.desc;
      }
      return `<p style="font-weight:700;">
          ${val || '--'}
        </p>
        <p class="color9" style="height:20px;line-height:20px;">
          ${desc}
        </p>`;
    },
    actionValueDesc(field, value, row) {
      if (field == 'cost_per_action_type' || field == 'object_actions') {
        return `${value || '-'}${row[field + '_desc'] || ''}`;
      }
      return value;
    },
    actionValueDescV2(field, value, row) {
      if (field == 'cost_per_action_type' || field == 'object_actions') {
        if (row[field + '_desc']) {
          return {
            val: value || '--',
            desc: row[field + '_desc'],
          };
        }
      }
      return {
        val: value,
        desc: '',
      };
    },
    formatedMoney(v, base) {
      return this.$store.getters.toFormatedMoney(v, base).formated;
    },
    // 批量操作
    // 查看受众
    viewAudience(v) {
      this.currentAudienceAdset = v.row;
      this.showAudience = true;
      this.newMap = v;
    },
    f_editAudience() {
      console.log(this.newMap, '这是当前选中的列');
      this.editAudience(this.newMap);
    },
    // 编辑受众
    async editAudience(v) {
      this.$showLoading();
      let res = await adgroupAudienceInfo({ accountId: this.accountId[0], adGroupResourceName: v.row.resource_name });
      this.$hideLoading();
      if (res.code == 0) {
        this.ruleForm = res.data;
        this.originData = res.data.adgroup_criterias;
        this.createVisible = {
          top: '15vh',
          width: '900px',
          title: '编辑受众',
          dialogVisible: true,
        };
      }
    },

    // 点击编辑
    handleEditCommand(v) {
      switch (v.type) {
        // 编辑广告系列竞价预算
        case 'campaign':
        case 'adset':
          this.drawers.editBudgetPrice.visible = true;
          const rowScopes = v.scope ? [v.scope.row] : this.selectedRowScopes;
          const level = v.scope ? v.scope.row.level : this.level;
          this.drawers.editBudgetPrice.rowScopes = rowScopes;
          this.drawers.editBudgetPrice.level = level;
          break;
        // 编辑广告组
        case 'editAdset':
          this.editGroupShow = true;
          this.editAdSetId = v.scope.row.id;
          this.adsetScope = v.scope;
          break;
        // 编辑广告
        case 'editAd':
          this.editAdsShow = true;
          this.editAdId = v.scope.row.id;
          this.adScope = v.scope;
          this.f_editAdCommand(v);
          break;
        // 创建广告
        case 'creatAd':
          this.batchEditAds = true;
          this.creatAdParentId = v.scope.row.id;
          break;
        // 批量修改广告创意
        case 'ad':
          this.batchEditAds = true;
          break;
        // 批量修改广告名称
        case 'adName':
          this.editNameShow = true;
          break;
        // 批量修改广告网域
        case 'adUrl':
          this.editUrlShow = true;
          break;
        // 批量修改像素
        case 'adsetPix':
          this.editPixelShow = true;
          break;
        // 批量修改广告组
        case 'adsets':
          this.batchEditShow = true;
          break;
        // 批量修改广告组名称
        case 'adsetName':
          this.editAdsetNameShow = true;
          break;
        // 编辑广告
        case 'editAds':
          this.batchEditShow = true;
          break;
        // 批量编辑主页
        case 'editPages':
          this.editPageShow = true;
          break;
        default:
          break;
      }
    },
    // field
    operatorName(tag) {
      const v = this.initData.FilterFields?.find((v) => v.keyName == tag.field);
      if (v) {
        const item = v.filtering.find((m) => m.key == tag.operator);
        return item ? item.desc : '';
      }
      return '请选择';
    },
    // 显示筛选条件符号下拉
    showFiltering(tag) {
      let list = this.initData.FilterFields?.find((v) => v.keyName == tag.field);
      return list ? list.filtering : [];
    },
    // 显示筛选条件的值
    valueName(tag) {
      let list = [];
      tag.value1 = tag.value1 || tag.value;
      tag.value1.map((item) => {
        if (tag.field == 'objective') {
          this.initData.CampaignObjective?.map((v) => {
            handleColumnCommand;
            if (item == v.key) {
              list.push(v.desc);
            }
          });
        } else {
          this.initData.FilteringStatus?.map((v) => {
            if (item == v.key) {
              list.push(v.desc);
            }
          });
        }
      });
      return list.length ? list.join(',') : '请选择';
    },
    // 获取我之前定制的列组合
    async myColumns() {
      if (!this.accountId) return;
      const params = { accountId: this.accountId.toString(), level: this.level };
      await myColumns(params).then((res) => {
        if (res.code == 0) {
          this.myCols.myColumnsArr = res.data;
          let myColumsId = localStorage.getItem('myColumsId_' + this.level);
          if (myColumsId) {
            this.fields = JSON.parse(localStorage.getItem('myColums_' + this.level));
          } else {
            this.fields = res.data[0].columns;
          }
          this.myCols.myColumsId = myColumsId;
        }
      });
    },
    // 切换筛选条件
    handleFilterCommand(v) {
      this.filterShow = true;
      let filtering = JSON.parse(JSON.stringify(v.filtering));
      this.params.filtering = filtering.map((v) => {
        if (v.field == 'objective' || v.field == 'status') {
          v.value1 = v.value;
        }
        return v;
      });
      this.debouncedReloadTable();
    },
    // 监听筛选条件value的变换
    valueChange(v) {
      this.saveFilterStorage();
      this.debouncedReloadTable();
    },
    // 筛选条件符号的变化
    operatorChange() {
      this.saveFilterStorage();
    },
    // 筛选条件存入缓存
    saveFilterStorage() {
      localStorage.setItem(`filter_${this.accountId}`, JSON.stringify(this.params.filtering));
    },
    objectFilteringBySection() {
      let filteringCampaignIds = [];
      let filteringAdseIds = [];
      if (this.selectIds && this.selectIds.selectCampaigns && this.selectIds.selectCampaigns.length > 0) {
        let obj = {
          field: 'campaign.id',
          operator: 'IN',
          value: this.selectIds.selectCampaigns.map((v) => v.id),
        };
        filteringCampaignIds.push(obj);
      }
      if (this.selectIds && this.selectIds.selectAdsets && this.selectIds.selectAdsets.length > 0) {
        let obj = {
          field: 'adset.id',
          operator: 'IN',
          value: this.selectIds.selectAdsets.map((v) => v.id),
        };
        filteringAdseIds.push(obj);
      }
      switch (this.level) {
        case 'adset':
          return filteringCampaignIds;
        case 'ad':
          return filteringCampaignIds.concat(filteringAdseIds);
      }
      return [];
    },
    tabChangeReloadTable(level) {
      // console.log(this.activeLevel);
      if (level == 'campaign') {
        if (JSON.stringify(this.campaignDateRangNew) !== JSON.stringify(this.campaignDateRangOld)) {
          this.$store.commit('adActiveTab/setCampaignDateRangOld', this.dateRange);
          this.debouncedReloadTable();
          this.setSel();
        }
      } else if (level == 'adset') {
        if (
          JSON.stringify(this.selectCampaignsOld) !== JSON.stringify(this.selectCampaignsNew) ||
          JSON.stringify(this.adsetDateRangNew) !== JSON.stringify(this.adsetDateRangOld)
        ) {
          this.$store.commit('adActiveTab/setSelectCampaignOld', this.selectCampaignsNew);
          this.$store.commit('adActiveTab/setAdsetDateRangOld', this.dateRange);
          this.debouncedReloadTable();
          this.setSel();
        }
      } else if (level == 'ad') {
        if (
          JSON.stringify(this.selectAdsetsOld.campaign) !== JSON.stringify(this.selectCampaignsNew) ||
          JSON.stringify(this.selectAdsetsOld.adset) !== JSON.stringify(this.selectAdsetsNew) ||
          JSON.stringify(this.adDateRangNew) !== JSON.stringify(this.adDateRangOld)
        ) {
          this.$store.commit('adActiveTab/setSelectAdsetsOld', {
            campaign: this.selectCampaignsNew,
            adset: this.selectAdsetsNew,
          });
          this.$store.commit('adActiveTab/setAdDateRangOld', this.dateRange);
          this.debouncedReloadTable();
        }
      }
    },
    // 选中状态回显
    setSel() {
      let sel = [];
      if (this.level == 'adset') {
        sel = this.selectIds.selectAdsets;
      }
      sel.forEach((item) => {
        let obj = this.tableData.find((row) => row.id == item.id);
        if (obj) {
          this.$refs.table.toggleRowSelection(obj, true);
        }
      });
    },
    async reloadTable() {
      if (!this.accountId || !this.accountId.length) return;
      if (!this.fields || !this.fields.length) {
        await this.myColumns();
      }
      //初始化，或刷新
      document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
      this.after = '';
      let params = JSON.parse(JSON.stringify(this.params));
      let filedList = this.fields.map((v) => v.keyName);
      params.fields = ['name', 'status', 'id', 'campaign_ref', 'campaign_id', 'adset_id', 'purchase'].concat(filedList);
      params.after = '';
      // 处理筛选条件
      let filtering = JSON.parse(JSON.stringify(params.filtering));
      filtering.map((item) => {
        if (item.field == 'status' || item.field == 'objective') {
          item.value = item.value1 ? JSON.parse(JSON.stringify(item.value1)) : item.value;
        }
        delete item.value1;
      });
      // 从filtering 里面取出object_filtering 的项
      let object_filtering = filtering.filter((v) => v.field == 'status' || (v.field == 'objective' && v.value.length));
      params.object_filtering = object_filtering.concat(this.objectFilteringBySection());
      params.filtering = filtering.filter((v) => v.field != 'status' && v.field != 'objective');
      params.level = this.level;
      if (this.level !== this.activeLevel) {
        return false;
      }
      this.downLoadParams = JSON.parse(JSON.stringify(params));
      return this.getList(params).then(async (res) => {
        this.tableData = res;
        if (!params.sort.length) {
          this.tableData.sort(this.compare('statusIndex', 'ascending'));
        }
        if (res.length < this.params.page_length || this.tableData.length == this.summary.total_count) {
          this.loadText = '没有更多数据了';
          this.infiniteScrollDisabled = true;
        } else {
          this.loadText = '滚动加载更多';
          this.infiniteScrollDisabled = false;
        }
        // 清除掉子项缓存
        Object.keys(this.$refs.table.store.states.lazyTreeNodeMap).map((v) => {
          delete this.$refs.table.store.states.lazyTreeNodeMap[v];
        });
        this.tableData.map((v) => {
          if (this.$refs.table.store.states.treeData[v]) {
            this.$refs.table.store.states.treeData[v].loaded = false;
            this.$refs.table.store.states.treeData[v].expanded = false;
            this.$refs.table.store.states.treeData[v].children = [];
            this.$set(this.$refs.table.store.states.treeData, v, this.$refs.table.store.states.treeData[v]);
          }
        });
        this.clearChidren(this.tableData.map((v) => v.id));

        // 广告系列-单独调用接口拿到经营分析数据
        if (this.level == 'campaign') {
        } else {
          this.setManageAssess();
        }
        this.$forceUpdate();
        return;
      });
    },
    // 和几行数据
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((item, index) => {
        if (index == 0) {
          sums[index] = '';
          return;
        } else if (index == 1) {
          let n = this.adsCountObj[this.level + 's'];
          sums[index] = `${n}条${
            this.level == 'campaign' ? '广告系列' : this.level == 'adset' ? '广告组' : '广告'
          }的成效`;
        } else {
          let row = this.fields.filter((v) => v.keyName == item.property)[0];
          if (row) {
            sums[index] = this.summary ? this.formatedCellValue(this.summary, row) : '--';
          } else {
            sums[index] = '--';
          }
        }
      });
      return sums;
    },
    // 删除当前筛选条件
    deleFilterTag(tag, i) {
      this.params.filtering.splice(i, 1);
      if (!this.params.filtering.length) {
        this.filterShow = false;
      }
      if (tag.field == 'status') {
        this.checkList = [];
      }
      tag.value1 = tag.value1 ? tag.value1 : [];
      if (tag.field && tag.operator && (tag.value !== '' || tag.value1.length)) {
        this.saveFilterStorage();
        this.debouncedReloadTable();
      }
    },
    cannotChangeField(list, filterItem) {
      return (
        (filterItem.keyName == 'status' || filterItem.keyName == 'objective') &&
        !!list.find((v) => v.field == filterItem.keyName)
      );
    },
    // 监听筛选条件field的变化
    fieldChange(list, v, i) {
      let obj = JSON.parse(JSON.stringify(v));
      const filtering = this.initData.FilterFields.find((item) => item.keyName == v.field);
      if (filtering && filtering.filtering.length == 1) {
        obj.operator = filtering.filtering[0].key;
      } else {
        obj.operator = '';
      }
      obj.value = '';
      obj.value1 = [];
      this.$set(list, i, obj);
    },
    // 删除筛选条件
    deleFilterObj(i) {
      this.cusFilterList.splice(i, 1);
    },
    // 控制筛选条件下拉不被隐藏
    showFilterDrop() {
      this.$nextTick((res) => {
        this.$refs.filterDrop.dropdown.show();
      });
    },
    // 添加筛选条件
    addFilter() {
      let cusFilterList = this.cusFilterList;
      let flag = true;
      cusFilterList.map((item) => {
        if (!item.field || !item.operator || (!item.value && !item.value1.length)) {
          this.$message({
            type: 'warning',
            message: '请填写完整的信息',
          });
          flag = false;
        } else {
        }
      });
      if (flag) {
        this.cusFilterList.push({
          field: '',
          operator: '',
          value: '',
          value1: [],
        });
      }
      return flag;
    },
    // 添加当前筛选条件
    addTag() {
      let obj = { field: '', operator: '', value: '', value1: [] };
      this.params.filtering.push(obj);
    },
    // 保存为我的筛选条件
    saveMyFilter() {
      let data = {
        name: this.filterName,
      };
      if (!this.filterName) {
        this.$message({
          type: 'warning',
          message: '请输入筛选条件名称',
        });
        return false;
      }
      let filters = this.params.filtering;
      if (!filters.length) {
        this.$message({
          type: 'warning',
          message: '请添加筛选条件再保存',
        });
        return false;
      }
      data.filtering = filters;
      this.$showLoading();
      data.filtering.map((v) => {
        v.value = v.value1 && v.value1.length ? v.value1 : v.value;
      });
      // this.loading=true
      saveFilter(data).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.saveFilterShow = false;
          this.filterName = '';
          this.getMyFilter();
        }
      });
    },
    // 关闭当前的筛选条件
    closeMyFilter() {
      this.filterShow = false;
      this.cusFilterList = [{ field: '', operator: '', value: '', value1: [] }];
      this.params.filtering = [];
      this.checkList = [];
      this.saveFilterStorage();
      this.debouncedReloadTable();
    },
    // 保存为当前的筛选条件
    saveFilterSearch() {
      if (this.addFilter()) {
        this.filterShow = true;
        this.params.filtering = JSON.parse(JSON.stringify(this.cusFilterList)).slice(0, this.cusFilterList.length - 1);
        if (this.params.filtering.filter((v) => v.field == 'status').length) {
          this.checkList = this.params.filtering.filter((v) => v.field == 'status')[0].value1;
        }
        this.saveFilterStorage();
        this.debouncedReloadTable();
      }
    },
    // 删除我的查询条件
    deleFilterGroup(v) {
      let formData = new FormData();
      formData.append('id', v);
      this.$showLoading();
      deleteFilter(formData).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.getMyFilter();
        }
      });
    },
    // 获取我的查询条件
    getMyFilter() {
      myFilters().then((res) => {
        this.filterList = res.data;
      });
    },
    // 定制列
    handleColumnCommand(v) {
      if (v == '定制列组合') {
        this.cusColumnVisible = true;
        this.myColumsName = '';
        this.initCheckedColumns = this.fields.map((v) => v.keyName);
      } else {
        localStorage.setItem('myColumsId_' + this.level, v.id);
        this.setColumsStorge(v.columns);
        this.myCols.myColumsId = v.id;
        this.fields = v.columns;
        this.debouncedReloadTable();
      }
    },
    // 编辑定制列
    editColumns(v) {
      this.cusColumnVisible = true;
      this.myColumsName = v.name;
      this.initCheckedColumns = v.columns.map((v) => v.keyName);
    },
    // 删除定制列
    deleColumns(v) {
      let formData = new FormData();
      formData.append('id', v.id);
      deleteColumn(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.myColumns();
        }
      });
    },
    // 保存当前列组合
    handleGetCusColumns(arr) {
      // 重新渲染表格
      this.fields = arr;
      this.params.fields = arr;
      this.setColumsStorge(arr);
      this.debouncedReloadTable();
    },
    // 保存列组合，以及我的列组合
    handleGetSaveRes(res) {
      // 重新渲染定制列下拉框数据
      this.fields = res;
      this.params.fields = res;
      this.setColumsStorge(res);
      // 重新渲染表格
      this.debouncedReloadTable();
      this.myColumns();
    },
    setColumsStorge(arr) {
      localStorage.setItem('myColums_' + this.level, JSON.stringify(arr));
    },
    // 关键词变化
    keywordChange() {
      this.debouncedReloadTable();
    },
    // 自定义排序
    compare(propertyName, sort) {
      return function (obj1, obj2) {
        var value1 = obj1[propertyName] || 0;
        var value2 = obj2[propertyName] || 0;
        if (typeof value1 === 'string' && typeof value2 === 'string') {
          const res = value1.localeCompare(value2, 'zh');
          return sort === 'ascending' ? res : -res;
        } else {
          if (value1 <= value2) {
            return sort === 'ascending' ? -1 : 1;
          } else if (value1 > value2) {
            return sort === 'ascending' ? 1 : -1;
          }
        }
      };
    },
    manualSort(item) {
      let key = item.keyName || item.prop;
      return [
        'add_payment_info',
        'add_payment_info_cost',
        'add_to_cart',
        'add_to_cart_cost',
        'add_to_cart_web',
        'add_to_cart_web_cost',
        'content_view_web',
        'content_view_web_cost',
        'credit_spent',
        'fb_pixel_add_payment_info',
        'fb_pixel_initiate_checkout',
        'initiated_checkout_web_cost',
        'initiate_checkout',
        'initiate_checkout_cost',
        'landing_page_view',
        'like',
        'link_click',
        'photo_view',
        'post',
        'purchase',
        'purchase_cost',
        'purchase_web',
        'purchase_web_cost',
        'purchase_web_value',
        'video_view',
        'view_content',
        'view_content_cost',
        'local_order',
        'effective_status',
      ].includes(key);
    },
    // 监听表格排序
    sortChange(v) {
      // 判断是否前端排序
      if (v.prop == 'name') {
        this.manualSortFlag = null;
      } else if (this.manualSort(v)) {
        const prop = v.prop == 'local_order' ? 'localsOrderNum' : v.prop == 'effective_status' ? 'statusIndex' : v.prop;
        this.tableData.sort(this.compare(prop, v.order));
        this.$refs.table.doLayout();
        this.manualSortFlag = v;
      } else {
        this.manualSortFlag = null;
        this.params.sort = v.order
          ? [
              {
                field: v.prop,
                direction: v.order == 'ascending' ? 'ASC' : 'DESC',
              },
            ]
          : [];
        this.debouncedReloadTable();
      }
    },
    // 点击创建广告类型
    handleCommandClick() {
      console.log(1);
      this.createVisible = {
        top: '5vh',
        width: '1100px',
        title: '创建发现广告',
        dialogVisible: true,
      };
      this.handleCommand(this.adType);
    },
    handleCommand(v) {
      this.adType = v;
    },
    // 表格表头筛选
    selectStatus(v) {
      const i = this.params.filtering.findIndex((m) => m.field == 'status');
      if (i >= 0) {
        const item = this.params.filtering[i];
        item.value1 = v;
        item.value = '';
        this.$set(this.params.filtering, i, item);
      } else {
        let obj = { field: 'status', operator: 'IN', value1: v, value: '' };
        this.params.filtering.push(obj);
      }
      this.debouncedReloadTable();
    },
    copyRow(scope) {
      this.list = [scope.row];
      console.log(this.list);
      this.f_copy();
    },
    // 修改状态
    setStatus(scope, status) {
      // 单个删除 传scope status 批量删除 传status  批量开启暂停传 status  单个开启暂停传scope
      this.mutateStatus(scope, status);
    },
    mutateStatus(scope, status) {
      let resourceNames = [];
      if (scope) {
        resourceNames = [scope.row.resource_name];
      } else {
        resourceNames = this.selectedRowScopes.map((item) => item.resource_name);
      }
      let params = {
        customerId: this.accountId.toString(),
        resourceNames,
        status: status || scope.row.status,
        level: scope.row.level || this.level,
      };
      mutateStatus(params).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '成功',
          });
          // scope.row.status=status;
          if (!scope) {
            this.selectedRowScopes.map((item, index) => {
              this.$set(this.selectedRowScopes[index], 'status', params.status);
            });
          }
          // }
        } else {
          this.$message({
            type: 'warning',
            message: '失败了',
          });
          if (scope) {
            scope.row.status =
              scope.row.status == 'ENABLED' ? 'PAUSED' : scope.row.status == 'PAUSED' ? 'ENABLED' : scope.row.status;
          }
        }
      });
    },
    // 清除行的子项
    clearChidren(list) {
      list.forEach((k) => {
        Object.keys(this.$refs.table.store.states.lazyTreeNodeMap).forEach((v) => {
          if (v == k) {
            delete this.$refs.table.store.states.lazyTreeNodeMap[v];
          }
        });
        const treeData = this.$refs.table.store.states.treeData[k];
        if (treeData) {
          treeData.loaded = false;
          treeData.expanded = false;
          treeData.children = [];
          this.$set(this.$refs.table.store.states.treeData, k, treeData);
        }
      });
      this.tableData.forEach((item, index) => {
        if (list.includes(item.id)) {
          item.hasChildren = true;
          this.$refs.table.toggleRowExpansion(item, false);
          this.$set(this.tableData, index, item);
        }
      });
    },
    // 批量修改竞价预算之后刷新列表部分值
    refreshTablePrice(list) {
      this.clearChidren(list);
      this.$refs.table.store.states.data.forEach((v, i) => {
        const item = list.find((item) => v.id == item.id);
        if (item) {
          v.daily_budget = item.daily_budget * 100 || v.daily_budget;
          v.lifetime_budget = item.lifetime_budget * 100 || v.lifetime_budget;
          v.bid_amount = item.bid_amount * 100 || v.bid_amount;
        }
      });
    },
    // 刷新当前行
    refreshTableRow(scope) {
      let row = scope.row;
      // 如果存在子级重新加载子级
      let obj = this.maps.get(row.id);
      if (obj) {
        this.$refs.table.store.states.treeData[row.id].loaded = false;
        this.$refs.table.store.states.treeData[row.id].expanded = false;
      }
      this.$forceUpdate();
    },
    // expandChange 切换表格的展开项
    expandChange(row, expanded) {
      if (!this.$refs.table.store.states.lazyTreeNodeMap[row.id] && expanded && row.level != 'ad') {
        if (this.maps.get(row.id)) {
          const { tree, treeNode, resolve } = this.maps.get(row.id);
        }
      }
    },

    // 加载广告系列子集
    loadChildren(tree, treeNode, resolve) {
      let level = '';
      let product_report = tree.product_report;
      let adProductReport = tree.adProductReport;
      let purchase = tree.object_actions || tree.purchase || tree.purchase_web;
      let cost = tree.spend;
      this.maps.set(tree.id, { tree, treeNode, resolve });
      if (this.$refs.table.store.states.lazyTreeNodeMap[tree.id]) {
        resolve(this.$refs.table.store.states.lazyTreeNodeMap[tree.id]);
        return false;
      }
      let obj;
      if (tree.level == 'campaign') {
        level = 'adset';
        obj = { field: 'campaign.id', operator: 'IN', value: [tree.id] };
        this.params.object_filtering.push(obj);
      } else if (tree.level == 'adset') {
        level = 'ad';
        obj = { field: 'adset.id', operator: 'IN', value: [tree.id] };
        this.params.object_filtering.push(obj);
      } else {
        setTimeout(() => {
          resolve([]);
        }, 100);
        return false;
      }
      let params = JSON.parse(JSON.stringify(this.params));
      let filedList = this.fields.forEach((v) => v.keyName);
      params.fields = ['name', 'status', 'id', 'campaign_ref', 'campaign_id', 'adset_id', 'purchase'].concat(filedList);
      params.object_filtering = [obj];
      let filtering = JSON.parse(JSON.stringify(this.params.filtering));
      params.filtering = filtering.filter((v) => v.field != 'status' && v.field != 'objective' && v.value !== '');
      params.level = level;
      params.after = '';
      this.getList(params).then((res) => {
        // 给每一行加上经营分析
        // 不加载广告经营分析
        if (tree.level == 'campaign') {
          res.forEach((v) => {
            v.product_report = product_report;
            v.adProductReport = adProductReport;
            v.campaignPurchase = purchase;
            v.campaignCost = cost;
          });
        }
        setTimeout(() => {
          resolve(res);
        }, 200);
      });
    },
    // 获取点击name传过来的id
    openNextLevel(row, level) {
      this.$refs.table.clearSelection();
      this.$refs.table.toggleRowSelection(row, true);
      this.$emit('selection-change', this.level, [row]);
      this.$emit('select-tab', { name: level, row });
    },
    // 监听选择表格的选择项
    handleSelectionChange(v) {
      console.log('v1', v);
      this.selectedRowScopes = v;
      this['audiences/setMultipleAdsets'](v);
      this.list = v;
      if (this.level == 'campaign') {
        this.$store.commit('adActiveTab/setSelectCampaignNew', v);
      } else if (this.level == 'adset') {
        this.$store.commit('adActiveTab/setSelectAdsetsNew', v);
      }
      this.$emit('selection-change', this.level, v);
    },
    // 清除表格选择项
    clearIds() {
      this.selectedRowScopes = [];
      this.$emit('selection-change', this.level, []);
      this.$refs.table.clearSelection();
    },
    // 下拉加载更多
    loadMore() {
      if (this.infiniteScrollDisabled) {
        return;
      }

      let params = JSON.parse(JSON.stringify(this.params));

      let filedList = this.fields.map((v) => v.keyName);
      params.fields = ['name', 'status', 'id', 'campaign_ref', 'campaign_id', 'adset_id', 'purchase'].concat(filedList);

      params.object_filtering = params.object_filtering.filter(
        (v) => v.field != 'adset.id' && v.field != 'campaign.id',
      );
      // 处理筛选条件
      let filtering = JSON.parse(JSON.stringify(params.filtering)).filter(
        (v) => (v.value !== '' || v.value1.length) && v.field && v.operator,
      );

      filtering.map((item) => {
        if (item.field == 'status' || item.field == 'objective') {
          item.value = JSON.parse(JSON.stringify(item.value1));
        }
      });
      params.object_filtering = filtering
        .filter((v) => v.field == 'status' || (v.field == 'objective' && v.value !== '' && v.value.length))
        .concat(this.objectFilteringBySection());
      params.filtering = filtering.filter((v) => v.field != 'status' && v.field != 'objective' && v.value !== '');

      let data = Object.assign({}, params, {
        after: this.after,
        level: this.level,
      });

      this.loadText = '努力加载中...';
      this.downLoadParams = JSON.parse(JSON.stringify(data));
      let { product_report, adProductReport, campaignPurchase, campaignCost } = { ...this.tableData[0] };
      this.getList(data).then(async (res) => {
        // 广告组层级，滚动加载加上经营分析
        if (data.level == 'adset') {
          res.map((v) => {
            v.product_report = product_report;
            v.adProductReport = adProductReport;
            v.campaignPurchase = campaignPurchase;
            v.campaignCost = campaignCost;
          });
        }
        this.tableData = this.tableData.concat(res);
        // 是否是前端排序
        if (this.manualSortFlag) {
          this.sortChange(this.manualSortFlag);
        }
        if (!params.sort.length) {
          this.tableData.sort(this.compare('statusIndex', 'ascending'));
        }
        if (res.length == this.params.page_length && this.tableData.length < this.summary.total_count) {
          this.loadText = '滚动加载更多';
          this.infiniteScrollDisabled = false;
        } else {
          this.loadText = '没有更多数据了';
          this.infiniteScrollDisabled = true;
        }
        this.$refs.table.doLayout();
      });
    },
    // 获取列表
    async getList(data) {
      let params = data;
      params.fields = params.fields.concat(this.specialFields(params));
      this.cancelQuest();
      this.infiniteScrollDisabled = true;
      this.$showLoading();
      console.log(params, '组合前');
      params.account_ids = this.accountId;
      params.fields = [...new Set([...params.fields, 'resource_name'])];
      delete params.account_id;
      console.log(params, '组合后');
      return getAdsReport(params)
        .then((res) => {
          this.$hideLoading();
          this.infiniteScrollDisabled = false;
          this.$emit('getNum', params.level + 's', res.data.summary ? res.data.summary.total_count : 0);
          if (res.code != 0) {
            return [];
          }
          if (params.level == this.level && res.data.paging.after) {
            this.after = res.data.paging.after;
          }
          if (this.level == 'ad' && this.$refs.table) {
            this.$set(this.$refs.table.store.states, 'lazy', false);
          } else {
            this.$set(this.$refs.table.store.states, 'lazy', true);
          }
          res.data.data.forEach((v, index) => {
            // 给每一列加上本地订单数量,状态索引，方便本地排序
            v.localsOrderNum = v.local_order?.orderCount || 0;
            v.statusIndex = v.effective_status ? getIndex(v.effective_status, v.level) : 100;
            if (v.level != 'ad') {
              v.hasChildren = true;
            } else {
              v.hasChildren = false;
            }
            if (
              v.level == 'campaign' &&
              v.campaign_ref == 'NOT_REF' &&
              this.fields.some((item) => item.keyName === 'campaign_ref')
            ) {
              this.setRelateGoods(v);
              v.campaign_ref = 'REFING';
            }
          });
          // this.$refs.table.doLayout();
          this.summary = res.data.summary;
          return res.data.data ? res.data.data : [];
        })
        .catch(() => {
          this.$hideLoading();
          return [];
        });
    },
    setRelateGoods(data) {
      console.log(data, 'setRelateGoodsdata');
      console.log(this.$store.getters.accountId,'this.$store.getters.accountId',this.accountId)
      let params = {
        user_id: this.$store.state.user.userInfo.data.id,
        platform: 'google',
        account_id: this.accountId.toString(),
        campaign_id: data.campaign_id,
        campaign_name: data.name,
        route: 'assist',
      };
      console.log(params);
      bindAuto(params).then((res) => {
        if (res.code == 0) {
          data.campaign_ref = 'REF';
        } else {
          data.campaign_ref = 'NOT_REF';
        }
      });
    },
    // 列宽
    columnWidth(item) {
      return item.name.length >= 8
        ? '180'
        : item.name.length >= 5
        ? '110'
        : item.keyName == 'budget'
        ? '200'
        : item.keyName == 'local_order'
        ? '120'
        : item.keyName == 'local_event'
        ? '180'
        : item.width > 80
        ? item.width
        : '80';
    },
  },
};
</script>

<style lang="scss">
.alignLeft {
  text-align: left;
  display: block;
}
.rule-tip {
  .rule-score-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.color9 {
  color: #999;
  font-size: 12px;
}
.ACTIVE {
  background: #67c23a;
}

.PAUSED,
.IN_PROCESS {
  background: #e6a23c;
}
.nameDELETED,
.nameARCHIVED {
  @extend .color9;
}

.DELETED {
  background: #f56c6c;
}

.DISAPPROVED {
  background: #ff0000;
}
.PREAPPROVED,
.ARCHIVED {
  background: #409eff;
}

.DELETED,
.WITH_ISSUES,
.PENDING_BILLING_INFO,
.ADSET_PAUSED {
  background: #f56c6c;
}

.PENDING_REVIEW,
.CAMPAIGN_PAUSED {
  background: #909399;
}

.adManagement-container .el-tabs__content {
  padding: 0;
}
.el-icon-info {
  color: #999;
  margin-left: 10px;
}
.el-icon-warning {
  color: #e6a23c;
}
.el-icon-error {
  color: #f56c6c;
}
.adManagement-container {
  .el-table .caret-wrapper {
    width: 0;
  }
}

.toolsCheckTips {
  .el-checkbox,
  .el-radio {
    line-height: 36px;
  }
}

.dropselect {
  .el-select-dropdown__item {
    padding: 0;

    span {
      display: block;
      padding: 0 20px;
    }
  }
}

.el-table__body-wrapper {
  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}

.statusWarp {
  display: flex;
  align-items: center;

  .pointer {
    flex: 8px 0 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.breakdownList {
  li {
    line-height: 26px;
  }
}

.cusFilter {
  padding: 0 10px 0 20px;

  .cusFilterList {
    .cusFilterItem {
      margin-bottom: 10px;
    }
  }
}

.el-dropdown-menu__item {
  &.active {
    background: #ecf5ff;
    color: #66b1ff;
  }

  .columnName {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      p {
        display: block;
      }
    }

    div {
      p {
        flex: 60px 0 0;
        display: none;

        i {
          margin: 0 5px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}

.tagInput {
  width: 30px;

  input {
    width: auto;
    border: none;
    outline: none;
    background: transparent;
    padding: 0 5px;
    width: 30px;
    border-bottom: 1px dashed #fff;
    color: #fff;
    height: 18px;
    line-height: 18px;
  }
}

.el-popover {
  min-width: 100px;
}
.drawerCon {
  height: calc(100vh - 150px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
.tableWrap {
  .el-table .cell {
    line-height: unset;
  }
}
// 状态
.header-status {
  .cell {
    display: flex !important;
    align-items: center;
  }
  .caret-wrapper {
    top: 2px;
  }
}
.reference {
  white-space: nowrap;
}
.my-table.el-table .el-table__body-wrapper {
  height: calc(100vh - 300px);
  overflow: auto;
}
.my-table.el-table .el-table__empty-block {
  height: auto !important;
}
</style>
<style lang="scss" scoped>
.toolsBar {
  margin: 10px 0;
  background: rgb(232, 241, 255);
  line-height: 32px;
  height: 42.38px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #409eff;
  font-size: 12px;
  .close {
    cursor: pointer;
  }
  .toolsCon {
    display: flex;
    align-items: center;
    i {
      font-size: 16px;
    }
    p {
      background: #409eff;
      line-height: 24px;
      border-radius: 14px;
      padding: 0 10px;
      font-size: 12px;
      color: #fff;
      margin-right: 10px;
    }
  }
}
.tableWrap {
  padding: 16px;
  padding-top: 0;

  .loadMore {
    text-align: center;
    line-height: 28px;
    color: #999;
  }
}
.el-table .caret-wrapper {
  width: 0;
}
.adManagement-container {
  width: 100%;
  height: calc(100vh - 82px);
  background: #fff;

  .filterWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecf5ff;

    .el-image {
      flex: 14px 0 0;
      margin-left: 5px;
    }

    .paramsFilters {
      flex: 1;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .el-tag {
        margin: 3px;
        display: flex;
        align-items: center;

        .checkbox-group {
          padding: 0 10px;

          .el-checkbox {
            display: block;
          }
        }
      }
    }

    .rightIcon {
      flex: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 15px;
        font-weight: bold;
        margin: 0 5px;
        cursor: pointer;
        color: #666;
      }
    }
  }

  .el-tabs__item {
    .el-button {
      padding: 4px 10px;
      margin-left: 5px;
    }
  }

  .table-top-bar {
    // position: absolute;
    // top: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    // height: 32px;
    padding: 10px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightTools {
      flex: 260px 0 0;
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;

      .tools {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .toolsItem {
          margin: 0 4px;
        }
      }

      .searchInput {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .el-button {
          color: #666;
          font-size: 14px;
          margin: 0 5px;
        }
      }
    }

    .midFilter {
      flex: 1;
      padding: 0 10px;
    }

    .table-top-bar-right {
      flex: 1 1 0%;
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.3s linear 0s;
      // padding-right: 140px;
      .filter-btn {
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 12px;
        color: #8c8c8c;
        margin-left: 12px;
        padding: 0 4px;
        border-radius: 2px;
        transition: all 0.3s linear 0s;
        cursor: pointer;

        img {
          margin-right: 4px;
        }
      }

      .filter-btn:hover {
        background: #eaeaed;
      }

      .filter-operation {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        width: auto;
        padding-left: 10px;
        transition: all 0.3s linear 0s;

        .filter-operation-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          transition: all 0.3s linear 0s;
          cursor: pointer;

          i {
            color: gray;
          }
        }

        .filter-operation-item:hover {
          background: #eaeaed;
        }
      }
    }
  }
}
// 批量操作的图标去掉直接展示文字
.operate-btn {
  margin-right: 5px;
}
</style>
